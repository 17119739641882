<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-card>
          <b-card-text>
            <div>
              <b-form @reset="resetForm">
                <b-form-group label="Nombre:">
                  <b-form-input
                    v-model="form.name"
                    type="text"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Código">
                  <b-form-input
                    v-model="form.code"
                    type="text"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Dirección">
                  <b-form-input
                    v-model="form.address"
                    type="text"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Ubigeo">
                  <multiselect
                    v-model="form.ubigeo"
                    track-by="id"
                    label="canonical_name"
                    placeholder="Selecciona producto"
                    :options="ubigeos"
                    :searchable="true"
                    :allow-empty="false"
                    @search-change="searchUbigeo"
                  >
                  </multiselect>
                </b-form-group>
                <b-button variant="primary" class="mr-2" @click="submit"
                  >Guardar</b-button
                >
                <b-button type="reset" variant="danger" v-if="isEdit === false"
                  >Limpiar</b-button
                >
              </b-form>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "PersonCreate",
  data() {
    return {
      form: {},
      isEdit: false,
      action: "Crear",
      ubigeos: [],
    };
  },
  components: {},
  mounted() {
    this.checkIfIsEdit();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Direcciones", route: "/person" },
      { title: `Crear/editar dirección` },
    ]);
  },
  methods: {
    checkIfIsEdit() {
      console.log(this.$route);
      if ("id" in this.$route.params) {
        this.isEdit = true;
        this.action = "Editar";
        ApiService.get(`core/warehouse/${this.$route.params.id}`).then(
          (res) => {
            this.form = res.data;
            this.form.ubigeo = {
              id: this.form.ubigeo,
              ubigeo_canonical_name: this.form.ubigeo_canonical_name,
            };
          }
        );
      }
    },
    resetForm() {
      this.form = { ubigeo: null };
    },
    searchUbigeo(query) {
      if (typeof window.LIT !== "undefined") {
        clearTimeout(window.LIT);
      }
      window.LIT = setTimeout(() => {
        ApiService.get(
          "core/ubigeo",
          `?page=1&page_size=10&query=${query}`
        ).then((res) => {
          this.ubigeos = res.data.results;
        });
      }, 750);
    },
    submit() {
      let method = this.isEdit === true ? ApiService.put : ApiService.post;
      let form = { ...this.form };
      form.ubigeo = form.ubigeo.id;
      let url =
        this.isEdit === true
          ? `core/warehouse/${this.form.id}/`
          : "core/warehouse/";

      method(url, form)
        .then(() => {
          this.$successToast();
          if (this.isEdit === false) {
            this.resetForm();
          }
        })
        .catch((err) => {
          console.log(err);
          this.$errorToast(err);
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
